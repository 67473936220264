import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

const AwardCard = ({ name, img }) => {
  console.log("name", name);
  return (
    <Card
      sx={{
        width: name !== null ? 140 : 200,
        borderRadius: name === null ? "0" : "12%",
        minHeight: 100,
        display: "flex",
        //  border:"2px solid blue",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          sx={
            {
              //  marginTop:name===null&&"20px"
            }
          }
          height={name === null && "100"}
          image={img}
        />
        {name && (
          <CardContent>
            <Typography
              sx={{ textAlign: "center" }}
              gutterBottom
              variant="h5"
              component="div"
            >
              {name}
            </Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

export default AwardCard;
